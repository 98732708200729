import React from "react"
import { Link } from "gatsby"
import LogoText from "../common/LogoText"

const Footer = () => {
  return (
    <>
      <hr />
      <div className="container is-size-7" style={{ display: "flex", flexDirection: "row", paddingBottom: "2rem" }}>
        <div style={{ flex: 1, textAlign: "center" }} className="is-cursive">
          © <LogoText />.
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <Link to="/about">About JiggSwap</Link>
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <Link to="/contact">Contact</Link>
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    </>
  )
}

export default Footer
