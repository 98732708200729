import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import AuthModal from "../auth/AuthModal"

const Layout = ({ children }) => (
  <div className="layout-container">
    <AuthModal />

    <div className="layout-header">
      <Header />
    </div>

    <div className="layout-body">
      <main className="container"> {children}</main>
    </div>
    <div className="layout-footer">
      <Footer />
    </div>
  </div>
)

export default Layout
