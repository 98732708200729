// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coming-soon-js": () => import("./../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-help-js": () => import("./../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-puzzles-add-js": () => import("./../src/pages/puzzles/add.js" /* webpackChunkName: "component---src-pages-puzzles-add-js" */),
  "component---src-pages-puzzles-js": () => import("./../src/pages/puzzles.js" /* webpackChunkName: "component---src-pages-puzzles-js" */),
  "component---src-pages-puzzles-update-js": () => import("./../src/pages/puzzles/update.js" /* webpackChunkName: "component---src-pages-puzzles-update-js" */),
  "component---src-pages-puzzles-view-js": () => import("./../src/pages/puzzles/view.js" /* webpackChunkName: "component---src-pages-puzzles-view-js" */),
  "component---src-pages-reset-password-js": () => import("./../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-trade-start-js": () => import("./../src/pages/trade/start.js" /* webpackChunkName: "component---src-pages-trade-start-js" */),
  "component---src-pages-trades-js": () => import("./../src/pages/trades.js" /* webpackChunkName: "component---src-pages-trades-js" */),
  "component---src-pages-user-js": () => import("./../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

