import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import jiggswapApi from "../../../api/jiggswap"
import { MapApiErrors } from "../../../helpers/ErrorParser"
import { AuthContext } from "../../../context/AuthContext"
import JiggswapInput from "../../forms/JiggswapInput"

const OAuthUsernameForm = ({ oauthInfo }) => {
  const { login } = useContext(AuthContext)

  const { register, handleSubmit, errors, setError } = useForm()

  const submitForm = async ({ username }) => {
    try {
      let response = await jiggswapApi.post(`/auth/signup/${oauthInfo.provider}`, {
        token: oauthInfo.token,
        username,
        validateUsername: true,
      })

      login(response.data)
    } catch (err) {
      let apiErrors = MapApiErrors(err.response.data.errors)
      setError(apiErrors)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <JiggswapInput name="username" label="Username" type="text" register={register} errors={errors} />

        <div className="has-text-centered">
          <button type="submit" className="button is-primary">
            Choose Username
          </button>
        </div>
      </form>
    </>
  )
}

export default OAuthUsernameForm
