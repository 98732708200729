import React from "react"
import { ErrorMessage } from "react-hook-form"

const JiggswapInput = ({ name, label, type, placeholder, helptext, register, errors }) => {
  const hasErrors = errors && errors[name] !== undefined

  const labelText = label || name[0].toUpperCase() + name.substr(1)

  return (
    <div className="field">
      <label className="label" htmlFor={name}>
        {labelText}

        {helptext && <p className="help has-text-weight-normal">{helptext}</p>}
      </label>

      <div className="control">
        <input
          id={name}
          placeholder={placeholder}
          name={name}
          type={type}
          className={"input" + (hasErrors ? " is-danger" : "")}
          ref={register}
          aria-label={name}
        />

        {hasErrors && (
          <div className="help is-danger">
            <ErrorMessage errors={errors} name={name} />
          </div>
        )}
      </div>
    </div>
  )
}

export default JiggswapInput
