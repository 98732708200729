import React, { useContext, useState } from "react"
import { Link } from "gatsby"

import { AuthContext } from "../../context/AuthContext"
import LogoText from "../common/LogoText"

const NavLink = ({ ...item }) => {
  if (item.class) {
    return (
      <div className="navbar-item">
        <Link to={item.to} className={item.class}>
          {item.content}
        </Link>
      </div>
    )
  }

  return (
    <Link to={item.to} className="navbar-item">
      {item.content}
    </Link>
  )
}

const Header = () => {
  const {
    state: { isAuth, username },
    setAuthModalVisibility,
  } = useContext(AuthContext)

  const [isOpen, setIsOpen] = useState(false)

  const DesktopNav = () => (
    <div className={`navbar-menu`}>
      <div className="navbar-start">
        <NavLink to="/puzzles" content="Available Puzzles" />
        {isAuth && <NavLink to="/trades" content="My Trades" />}
        {isAuth && <NavLink to="/puzzles/add" content="Add a Puzzle" />}
      </div>

      <div className="navbar-end">
        {isAuth && <NavLink to={"/user?username=" + username} content={"My Profile"} />}
        {isAuth && <NavLink to="/logout" content="Log out" />}
        {!isAuth && (
          <>
            <div className="navbar-item">
              <div className="buttons">
                <button type="button" className="button is-secondary" onClick={() => setAuthModalVisibility(true)}>
                  Log in
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )

  const TabletNav = () => (
    <>
      <div className="is-hidden-desktop navbar-item" style={{ marginLeft: "auto" }}>
        <button type="button" className="button is-primary nav-menu-button" onClick={() => setIsOpen(true)}>
          Menu
        </button>

        <>
          <div
            className={`sidebar-nav-overlay cursor-pointer ${isOpen ? "is-active" : ""}`}
            onClick={() => setIsOpen(false)}
            onKeyPress={() => setIsOpen(false)}
            role="none"
          ></div>
          <nav
            className={`sidebar-nav has-text-white ${isOpen ? "is-active" : ""}`}
            style={{ fontSize: "1.5rem" }}
            onClick={() => setIsOpen(false)}
            onKeyPress={() => setIsOpen(false)}
            role="none"
          >
            <Link to="#" className="navbar-item has-text-white" onClick={() => setAuthModalVisibility(false)}>
              <i className="fas fa-ban mr-1"></i>
              Close Menu
            </Link>

            <div style={{ border: "1px solid white" }}></div>

            {!isAuth && (
              <>
                <Link to="#" className="navbar-item has-text-white cursor-pointer" onClick={() => setAuthModalVisibility(true)}>
                  <i className="fas fa-sign-out-alt mr-1"></i>
                  Log in
                </Link>
              </>
            )}

            <Link className="navbar-item has-text-white" to="/puzzles">
              <i className="fas fa-puzzle-piece mr-1"></i>
              Available Puzzles
            </Link>

            <Link className="navbar-item has-text-white" to="/puzzles/add">
              <i className="fas fa-plus mr-1"></i>
              Add a Puzzle
            </Link>

            {isAuth && (
              <>
                <div style={{ border: "1px solid white" }}></div>

                <Link className="navbar-item has-text-white" to="/trades">
                  <i className="fas fa-exchange-alt mr-1"></i>
                  My Trades
                </Link>
                <Link className="navbar-item has-text-white" to={`/user?username=${username}`}>
                  <i className="fas fa-user mr-1"></i>
                  My Profile
                </Link>

                <Link className="navbar-item has-text-white" to="/logout">
                  <i className="fas fa-sign-out-alt mr-1"></i>
                  Log out
                </Link>
              </>
            )}

            <div style={{ border: "1px solid white" }}></div>

            <Link className="navbar-item has-text-white" to="/about">
              <i className="fas fa-info-circle mr-1"></i>
              About JiggSwap
            </Link>

            <Link className="navbar-item has-text-white" to="/contact">
              <i className="fas fa-comment-dots mr-1"></i>
              Contact
            </Link>
          </nav>
        </>
      </div>
    </>
  )

  return (
    <div>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <div className="is-size-3">
                <LogoText />
              </div>
            </Link>

            <TabletNav />
          </div>
          <DesktopNav />
        </div>
      </nav>
    </div>
  )
}

export default Header
