import React, { useContext, useEffect } from "react"
import { AuthContext } from "../context/AuthContext"
import { FacebookSDK } from "../api/facebook"
import { GoogleSDK } from "../api/google"
import { HotjarSDK } from "../api/hotjar"

const LocalSignin = () => {
  const { tryLocalSignin } = useContext(AuthContext)

  useEffect(() => {
    tryLocalSignin()

    //eslint-disable-next-line
  }, [])

  return (
    <>
      <HotjarSDK />
      <FacebookSDK />
      <GoogleSDK />
    </>
  )
}

export default LocalSignin
