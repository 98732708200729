/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { HelmetProvider } from "react-helmet-async"
import { AuthProvider } from "./src/context/AuthContext"
import "./src/scss/jiggcss/Site.scss"
import LocalSignin from "./src/components/LocalSignin"
import Layout from "./src/components/layout/Layout"

export const wrapRootElement = ({ element }) => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <LocalSignin />
        <Layout>{element}</Layout>
      </AuthProvider>
    </HelmetProvider>
  )
}
