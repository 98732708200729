/* 
  Input: {
    'Field1': ['Field1Error1', 'Field1Error2'], 
    'Field2': ['Field2Error1'] }
Output: [
  { type: 'ApiError', name: 'Field1', message: 'Field1Error1' }, ]
  { type: 'ApiError', name: 'Field1', message: 'Field1Error2' }, 
  { type: 'ApiError', name: 'Field2', message: 'Field2Error1' }] */

export const MapApiErrors = apiErrors => {
  let serverErrors = []

  for (let fieldName in apiErrors) {
    let lowercased = fieldName[0].toLowerCase() + fieldName.substr(1)

    for (let idx in apiErrors[fieldName]) {
      serverErrors.push({
        type: "apiError",
        name: lowercased,
        message: apiErrors[fieldName][idx],
      })
    }
  }

  return serverErrors
}
