import React, { useEffect } from "react"
import { loadScript } from "./helpers/loadScript"

export const FacebookSDK = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "717414018831586",
        autoLogAppEvents: true,
        status: true,
        xfbml: true,
        version: "v7.0",
      })
    }

    const loadPixel = () => {
      const fbScript = function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = "2.0"
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      }

      fbScript(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js")
      window.fbq("init", "1298459010547386")
      window.fbq("track", "PageView")
    }

    console.log("loading facebook sdk")
    loadScript("https://connect.facebook.net/en_US/sdk.js")
    loadPixel()

    //eslint-disable-next-line
  }, [])

  return <></>
}

export const FacebookLoginButton = ({ onResult }) => {
  const isAuth = false

  if (typeof window == "undefined") {
    return <></>
  }

  const loginFacebook = () => {
    window.FB.getLoginStatus((loginStatus) => {
      if (loginStatus.status === "connected") {
        onResult(loginStatus)
      } else {
        window.FB.login(
          (authData) => {
            onResult(authData)
          },
          { scope: "email,public_profile" }
        )
      }
    })
  }

  const logoutFacebook = () => {
    window.FB.logout()
  }

  return (
    <>
      {!isAuth && (
        <>
          <button
            onClick={loginFacebook}
            type="button"
            className="button has-text-white is-size-6 has-text-weight-semibold"
            style={{ backgroundColor: "#4267b2" }}
          >
            <i className="fab fa-facebook fa-lg" style={{ marginRight: "0.5rem" }}></i>Continue with Facebook
          </button>
        </>
      )}

      {isAuth && (
        <>
          <button
            onClick={logoutFacebook}
            className="button has-text-white is-size-6 has-text-weight-semibold"
            style={{ backgroundColor: "#4267b2" }}
          >
            <i className="fab fa-facebook fa-lg" style={{ marginRight: "0.5rem" }}></i> Log Out
          </button>
        </>
      )}
    </>
  )
}
