import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import JiggswapInput from "../../forms/JiggswapInput"
import { MapApiErrors } from "../../../helpers/ErrorParser"
import jiggswapApi from "../../../api/jiggswap"
import { AuthContext } from "../../../context/AuthContext"

const LoginForm = () => {
  const { login } = useContext(AuthContext)

  const { register, handleSubmit, errors, setError } = useForm()

  const submitForm = async ({ usernameOrEmail, password }) => {
    try {
      let response = await jiggswapApi.post("/auth/authorize/jiggswap", { usernameOrEmail, password })

      login(response.data)
    } catch (err) {
      let apiErrors = MapApiErrors(err.response.data.errors)
      setError(apiErrors)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)} autoComplete="off">
        <JiggswapInput name="usernameOrEmail" label="Username or Email" type="text" register={register} errors={errors} />

        <JiggswapInput name="password" type="password" register={register} errors={errors} />

        <div className="has-text-centered">
          <button type="submit" className="button is-primary">
            Log in
          </button>
        </div>
      </form>
    </>
  )
}

export default LoginForm
