import React, { useContext, useState, useEffect } from "react"
import { AuthContext } from "../../context/AuthContext"
import LogoText from "../common/LogoText"
import { Link, navigate } from "gatsby"
import { FacebookLoginButton } from "../../api/facebook"
import LoginForm from "./AuthModalForms/LoginForm"
import SignupForm from "./AuthModalForms/SignupForm"
import ResetForm from "./AuthModalForms/ResetForm"
import { GoogleLoginButton } from "../../api/google"
import jiggswapApi from "../../api/jiggswap"
import OAuthUsernameForm from "./AuthModalForms/OAuthUsernameForm"

const ModalHeader = ({ buttonIcon, onButtonClick, headerText, close }) => (
  <div style={{ display: "flex", alignContent: "space-between" }}>
    <button type="button" className="button is-white" onClick={onButtonClick} style={{ flexShrink: 1 }}>
      <span>
        <i className={`fas fa-${buttonIcon} has-text-grey`} aria-label="back"></i>
      </span>
    </button>
    <div style={{ marginLeft: "25px", flexShrink: 1, lineHeight: "40px" }} className="has-text-weight-medium is-size-5">
      {headerText}
    </div>

    <div style={{ flexGrow: 1 }}></div>

    <Link style={{ flexShrink: 1, textAlign: "right" }} to="/help" className="has-text-grey button is-white" onClick={close}>
      Help
    </Link>
  </div>
)

const AuthModal = () => {
  const {
    state: { showModal, redirectOnDismiss },
    setAuthModalVisibility,
    login,
  } = useContext(AuthContext)

  const close = () => {
    if (redirectOnDismiss && typeof window !== "undefined") {
      navigate("/")
    }

    setAuthModalVisibility(false)
  }

  const [oauthInfo, setOauthInfo] = useState(undefined)

  const [currentContent, setCurrentContent] = useState("initial")
  const isInitialContent = currentContent === "initial"
  const isSignupContent = currentContent === "signup"
  const isLoginContent = currentContent === "login"
  const isResetContent = currentContent === "reset"
  const isOauthEmailContent = currentContent === "oauthEmail"
  const isOauthUsernameContent = currentContent === "oauthUsername"

  const showInitialContent = () => {
    setCurrentContent("initial")
  }
  const showSignupContent = () => {
    setCurrentContent("signup")
  }
  const showLoginContent = () => {
    setCurrentContent("login")
  }
  const showResetContent = () => {
    setCurrentContent("reset")
  }
  const showOauthEmailContent = () => {
    setCurrentContent("oauthEmail")
  }
  const showOauthUsernameContent = () => {
    setCurrentContent("oauthUsername")
  }

  const signinGoogle = async (googleAuth) => {
    if (googleAuth.error) {
      console.log("google authentication error: ", googleAuth.error)
      return
    }

    const response = await jiggswapApi.post("/auth/authorize/google", { token: googleAuth.getAuthResponse().id_token })

    if (response.data.needEmail) {
      window.gapi.auth2.getAuthInstance().signOut()
      showOauthEmailContent()

      return
    }

    if (response.data.needUsername) {
      setOauthInfo({ provider: "google", token: googleAuth.getAuthResponse().id_token })
      showOauthUsernameContent()
      return
    }

    if (window.fbq) {
      window.fbq("event", "CompleteRegistration")
    }

    login(response.data)
  }

  const signinFacebook = async (facebookAuth) => {
    if (!facebookAuth || facebookAuth.authResponse == null) {
      console.log("facebook login failed")
      return
    }

    const response = await jiggswapApi.post("/auth/authorize/facebook", { token: facebookAuth.authResponse.accessToken })

    if (response.data.needEmail) {
      window.FB.logout()
      showOauthEmailContent()
      return
    }

    if (response.data.needUsername) {
      setOauthInfo({ provider: "facebook", token: facebookAuth.authResponse.accessToken })
      showOauthUsernameContent()
      return
    }

    if (window.fbq) {
      window.fbq("event", "CompleteRegistration")
    }

    login(response.data)
  }

  useEffect(() => {
    if (showModal) {
      showInitialContent()
      const callApi = async () => {
        try {
          await jiggswapApi.get("/auth/opened")
        } catch (e) {}
      }
      callApi()
    }
  }, [showModal])

  if (!showModal) {
    return <></>
  }

  const InitialModalContent = () => (
    <>
      <ModalHeader buttonIcon="times" onButtonClick={close} close={close} headerText={""} />

      <div className="has-text-centered is-size-3">
        <LogoText />
      </div>
      <div style={{ margin: "30px 0" }} className="has-text-centered">
        Swap puzzles with other puzzle lovers!
      </div>
      <div className="has-text-centered ">
        <div className="is-uppercase has-text-grey-light" style={{ paddingBottom: "15px" }}>
          Quickly Connect
        </div>

        <FacebookLoginButton onResult={signinFacebook} />

        <div style={{ margin: "15px 0" }}></div>

        <GoogleLoginButton onResult={signinGoogle} />
      </div>
      <div className="has-text-centered is-uppercase has-text-grey-light" style={{ margin: "30px 0" }}>
        Or use your email
      </div>

      <div className="has-text-centered">
        <div className="buttons is-centered">
          <button type="button" className="button is-primary" onClick={showSignupContent}>
            Sign up for free
          </button>
          <button type="button" className="button is-primary" onClick={showLoginContent}>
            Log in
          </button>
        </div>
      </div>
    </>
  )

  const LoginContent = () => (
    <>
      <ModalHeader buttonIcon="arrow-left" onButtonClick={showInitialContent} close={close} headerText="Log in" />

      <LoginForm />

      <div className="has-text-centered">
        <div>
          <button type="button" onClick={showResetContent} className="has-text-primary  button is-white is-small">
            Forgot your password?
          </button>
        </div>
        <div>
          <button type="button" onClick={showSignupContent} className="has-text-primary  button is-white is-small">
            Don't have an account?
          </button>
        </div>
      </div>
    </>
  )

  const SignupContent = () => (
    <>
      <ModalHeader buttonIcon="arrow-left" onButtonClick={showInitialContent} close={close} headerText="Create a new account" />

      <SignupForm />

      <div className="has-text-centered">
        <div>
          <button type="button" onClick={showLoginContent} className="has-text-primary button is-white is-small">
            Already have an account?
          </button>
        </div>
      </div>
    </>
  )

  const ResetContent = () => (
    <>
      <ModalHeader buttonIcon="arrow-left" onButtonClick={showLoginContent} close={close} headerText="Reset Password" />

      <ResetForm />
    </>
  )

  const OauthEmailContent = () => (
    <>
      <ModalHeader buttonIcon="arrow-left" onButtonClick={showInitialContent} close={close} headerText="Need Email" />

      <div className="has-text-centered">
        Sorry, we are only accepting sign-ins from Facebook accounts that have verified email addresses at this time.
      </div>

      <div className="has-text-centered">
        <div>
          <button type="button" onClick={showLoginContent} className="has-text-primary button is-white is-small">
            Sign up with Email
          </button>
        </div>
      </div>
    </>
  )

  const OauthUsernameContent = () => (
    <>
      <ModalHeader buttonIcon="arrow-left" onButtonClick={showInitialContent} close={close} headerText="Choose a Username" />

      <div className="has-text-centered">
        Welcome to <LogoText />!
      </div>

      <div className="is-size-6 has-text-centered">Since this is your first time here, please pick a username you'd like to use.</div>

      <OAuthUsernameForm oauthInfo={oauthInfo} />

      <div className="has-text-centered">
        <div>
          <button type="button" onClick={showLoginContent} className="has-text-primary button is-white is-small">
            Sign up with Email instead
          </button>
        </div>
      </div>
    </>
  )

  return (
    <div className="modal is-active">
      <div className="modal-background" aria-label="close modal" onClick={close} onKeyPress={close} tabIndex="0" role="button"></div>
      <div className="modal-content" style={{ width: "400px" }}>
        <div className="box">
          {isInitialContent && <InitialModalContent />}
          {isLoginContent && <LoginContent />}
          {isSignupContent && <SignupContent />}
          {isResetContent && <ResetContent />}
          {isOauthEmailContent && <OauthEmailContent />}
          {isOauthUsernameContent && <OauthUsernameContent />}
        </div>
      </div>
    </div>
  )
}

export default AuthModal
