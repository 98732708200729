import React, { useContext } from "react"
import { useForm } from "react-hook-form"

import jiggswapApi from "../../../api/jiggswap"
import { MapApiErrors } from "../../../helpers/ErrorParser"
import JiggswapInput from "../../forms/JiggswapInput"
import { AuthContext } from "../../../context/AuthContext"

const SignupForm = () => {
  const { handleSubmit, register, errors, setError } = useForm()
  const { login } = useContext(AuthContext)

  const submitForm = async (values) => {
    try {
      let response = await jiggswapApi.post("/auth/signup/jiggswap", values)

      if (window.fbq) {
        window.fbq("event", "CompleteRegistration")
      }

      login(response.data)
    } catch (err) {
      let apiErrors = MapApiErrors(err.response.data.errors)

      setError(apiErrors)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <JiggswapInput
          helptext="Your email will never be shown to other users. It's only used by Jiggswap so that we can communicate with you."
          name="email"
          type="email"
          register={register}
          errors={errors}
        />

        <JiggswapInput name="username" type="text" register={register} errors={errors} />

        <JiggswapInput name="password" type="password" register={register} errors={errors} />

        <div className="has-text-centered">
          <button type="submit" className="button is-primary">
            Sign up
          </button>
        </div>
      </form>
    </>
  )
}

export default SignupForm
