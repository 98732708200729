import React, { useEffect } from "react"
import GoogleLogin from "react-google-login"
import { loadScript } from "./helpers/loadScript"

const GOOGLE_CLIENT_ID = "1090345849598-jb3o7ph0ggq6tfhsp0lp931f6i3c5s7s.apps.googleusercontent.com"

export const GoogleSDK = () => {
  useEffect(() => {
    const onGoogleLoaded = (gapi) => {
      gapi.load("auth2", () => {
        gapi.auth2.init({
          client_id: GOOGLE_CLIENT_ID,
          scope: "profile",
        })
      })
    }

    const interval = setInterval(() => {
      if (window.gapi) {
        clearInterval(interval)
        onGoogleLoaded(window.gapi)
      }
    }, 500)

    console.log("loading google sdk")
    loadScript("https://apis.google.com/js/platform.js")

    //eslint-disable-next-line
  }, [])

  return <></>
}

export const GoogleLoginButton = ({ onResult }) => {
  return (
    <GoogleLogin buttonText="Continue with Google" clientId={GOOGLE_CLIENT_ID} onSuccess={onResult} onFailure={onResult} theme="dark" />
  )
}
