import createDataContext from "./createDataContext"

export const LocalStorageKeys = {
  jwt: "auth.jwt",
  username: "auth.username",
}

const authReducer = (state, action) => {
  let newState = {}
  switch (action.type) {
    case "login":
      newState = { ...action.payload, isAuth: true }
      break
    case "logout":
      newState = {}
      break
    case "setAuthModalVisibility":
      newState = { ...state, showModal: action.payload.shouldShow, redirectOnDismiss: action.payload.redirectOnDismiss }
      break
    default:
      newState = state
      break
  }

  return newState
}

const login = (dispatch) => {
  return (authData) => {
    if (typeof window === `undefined`) {
      return false
    }

    dispatch({ type: "login", payload: authData })

    localStorage.setItem(LocalStorageKeys.jwt, authData.token)
    localStorage.setItem(LocalStorageKeys.username, authData.username)
  }
}

const logout = (dispatch) => {
  return () => {
    if (typeof window === `undefined`) {
      return false
    }

    dispatch({ type: "logout" })

    localStorage.removeItem(LocalStorageKeys.jwt)
    localStorage.removeItem(LocalStorageKeys.username)
  }
}

const setAuthModalVisibility = (dispath) => {
  return (shouldShow, redirectOnDismiss) => {
    dispath({ type: "setAuthModalVisibility", payload: { shouldShow, redirectOnDismiss } })
  }
}

const tryLocalSignin = (dispatch) => {
  return () => {
    if (typeof window === `undefined`) {
      return false
    }

    let jwt = localStorage.getItem(LocalStorageKeys.jwt)

    if (jwt) {
      let payload = {
        token: jwt,
        username: localStorage.getItem(LocalStorageKeys.username),
      }
      dispatch({ type: "login", payload })
      return true
    }
    dispatch({ type: "logout" })
    return false
  }
}

export const { Provider, Context } = createDataContext(
  authReducer,
  { login, logout, tryLocalSignin, setAuthModalVisibility },
  { token: null, username: null, unloaded: true, showModal: false }
)

export const AuthContext = Context
export const AuthProvider = Provider
