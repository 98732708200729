import React, { useState } from "react"
import { useForm } from "react-hook-form"
import jiggswapApi from "../../../api/jiggswap"
import { MapApiErrors } from "../../../helpers/ErrorParser"
import JiggswapInput from "../../forms/JiggswapInput"

const ResetForm = () => {
  const { register, handleSubmit, errors, setError } = useForm()

  const [currentState, setCurrentState] = useState("ready")

  const isReady = currentState === "ready"
  const isSubmitting = currentState === "submitting"
  const isSubmitted = currentState === "submitted"

  const submitForm = async ({ email }) => {
    setCurrentState("submitting")

    try {
      await jiggswapApi.post("/auth/forgot-password", { email })

      setCurrentState("submitted")
    } catch (err) {
      var apiErrors = MapApiErrors(err.response.data.errors)

      setError(apiErrors)

      setCurrentState("ready")
    }
  }

  return (
    <>
      <div style={isReady ? undefined : { display: "none" }}>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="has-text-centered">Enter your email and we'll send you some reset instructions.</div>
          <JiggswapInput name="email" type="text" register={register} errors={errors} />

          <div className="has-text-centered">
            <button type="submit" className="button is-primary">
              Send Reset Instructions
            </button>
          </div>
        </form>
      </div>
      {isSubmitting && <div className="has-text-centered">Sending reset instructions...</div>}
      {isSubmitted && <div className="has-text-centered">We've sent some password reset instructions to your email.</div>}
    </>
  )
}

export default ResetForm
